import React from 'react'
import {  Col, Container,  Row } from 'react-bootstrap'
import SubscriptionCards from './SubscriptionCards'
import { useNavigate } from 'react-router-dom'




export function HomePackages() {

const navigate = useNavigate();
  
    

  return (
    <section style={{backgroundColor:'#031112'}} className='py-5' id='home-packages'>
      <Container className='py-3'>
<Row>
  <Col className='text-center text-white'>
  <p className='fs-5'>Your subscription is a means of preserving the Truth in society.</p>
    <h5 className=''><u>Subscription Plans</u></h5>
  </Col>
</Row>

      <SubscriptionCards onClick={()=>navigate('/auth/signup')}/>

      <Row>
  <Col className='text-center text-white pt-md-5 pt-4 home-package-text'
   >
  <p className='mb-0'    style={{fontSize:`${window.innerWidth >= 768 ? '1.25rem':'0.8rem'}`}}
  >String is the only standing platform in the world that is combating the bias of Big Tech.</p>
  <p    style={{fontSize:`${window.innerWidth >= 768 ? '1.25rem':'0.8rem'}`}}
  >Through String come! Let’s tell the glory of Bharat and be a nightmare to the evil that is trying to destroy Sanatana Dharma.</p>
    
  </Col>
</Row>
      </Container>
        {/* <PricingPackages /> */}
       

    </section>
  )
}

