import React from 'react'
import { Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

function FooterLogo() {

  const navigate = useNavigate();

  return (
   <Image style={{cursor:'pointer',maxHeight:'50px'}}fluid onClick={()=>navigate('/')} src='/logo/string-geo-logo-white.png' />
  )
}

export default FooterLogo